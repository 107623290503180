.modal-footer,
.modal-header {
    border: 0;
}

.modal-header {
    .close {
        color: color('nijmegen-primary', 'base');
        margin-top: -.5rem;
        opacity: 1;
    }
}

.modal-title {
    @include web-font-rendering-support;

    color: $body-color;
    font-family: 'Oranda', sans-serif;
    font-size: to-rem(26);
    font-weight: $font-weight-normal;

    @media only screen and (min-width: #{$medium-screen-portrait}) {
        font-size: to-rem(28);
    }
}

.modal-body {
    padding-bottom: 0;
    padding-top: 0;
}
